<template>
  <BasicLayout :initVars="initVars">
    <template v-slot:layout-mainbox-custom>
      <v-main>
        <v-container fluid>
          <v-row>
            <div class="title">{{ initVars.title }}</div>
          </v-row>
        </v-container>
      </v-main>
    </template>
  </BasicLayout>
</template>
<script>
/**
 * [CRM中心]
 */
import BasicLayout from '@/layouts/basic';

export default {
  name: 'Dashboard',
  components: { BasicLayout },
  data () {
    return {
      initVars: {
        title: this.$route.meta.menuLabel
      }
    };
  },
  created () {
    // console.log('[Dashboard][created] ...');
  },
  mounted () {
    // console.log('[Dashboard][mounted] ...');
  },
  updated () {
    // console.log('[Dashboard][updated] ...');
  }
};
</script>
